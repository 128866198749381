// Step 1: Import React
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Layout} from "../../components/Layout"
import Button from '../../components/buttons/button'
import ProductBanner from "../../components/product_banners/product_banners";
import SlickProduct from "../../components/product_slick/slick_product";
import Faq from "../../components/faq/faq_open";
import Banner from "../../imgs/bg_dolares.jpg";
import faqjson from "../../misc/dolares_related/faq.json";
import slick_items from "../../misc/dolares_related/slick_items_dolares.json";
import {SalWrapper} from "react-sal";
import {graphql, Link, useStaticQuery} from "gatsby";
import { apiRequest } from "../../apiRquest/apiRequest";
import { Helmet } from "react-helmet";

const pageTitle = 'Ahorrá en Dólares | Balanz';
const pageDesc = 'Ahorrá en Dólares es fácil y seguro. Dolarizá tus ahorros mediante operaciones de compra y venta de bonos en nuestra web y app.';

let text = `Ahorrar en dólares es <br/><span class='highlightsbold'> fácil y seguro.</span>`;

const DolaresPage = () => {
    const [xs, setXs] = useState(false);
    const FaqsData = useStaticQuery(graphql`
        {
            allFaqs(
                filter: {category_id: {in: 9}},
                sort: {order: ASC, fields: [category_id, order]}
            ) {
                nodes {
                    category_id
                    id
                    answer
                    question
                }
            }
        }
    `);

    const [faq, setFaq] = useState({
        list: FaqsData.allFaqs.nodes
    });
    useEffect(() => {
        const handleXs = () => {
            const isXs = window.innerWidth <= 768;
            setXs(isXs);
        };

        window.addEventListener('resize', handleXs);

        handleXs();

        return () => {
            window.removeEventListener("resize", handleXs);
        };
    }, []);

    const slickSettings = {
        centerMode: false,
        slidesToShow: 5,
        dots: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 2920,
                settings:"unslick"
            },
            {
                breakpoint: 992,
                settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false }
            },
            {
                breakpoint: 768,
                settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
            },
            {
                breakpoint: 576,
                settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
            }
        ]
    };

    useEffect(() => {
        apiRequest
            .get("/v1/faq/9")
            .then((res) => {
                const data = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })

                })
                setFaq((old) => ({...old, list: data}));
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])

    const page = (
        <>
            <ProductBanner
                bannerContainerClassName="bannerOpciones"
                bannerTrasparecy="bannerMask"
                productTitle="Ahorrá en Dólares"
                bannerImage={Banner}
                bannerImageClassName="ocionesBannerImage"
                textContainerClassName="textContainer"
                bannerText={text}
                bannerTextClassName="bannerText1"
                bannerImageMobile={Banner}
                mobile={false}
            />
            <SalWrapper>
                <section className="fullwidth product-description bg-lt-grey">
                    <div className="bg-lt-grey"
                         data-sal="fade"
                         data-sal-delay="100"
                         data-sal-duration="200"
                    >
                        <Container>
                            <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                                <Col xs={12} >
                                    <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">Dolarizá tus ahorros mediante operaciones de compra y venta de bonos en nuestra web y app.
                                        <br />
                                        Podés invertir en dólares en los principales instrumentos del mercado, desde tu cuenta local en
                                        Balanz.
                                    </p>
                                    <div className="pt-4 text-center"
                                         data-sal="fade"
                                         data-sal-delay="300"
                                         data-sal-duration="300"
                                    >
                                        <Button id="dolares_invertir_1" variant="secondary" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                                    </div>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                </section>
                <section className="fullwidth operaciones">
                    <Container>
                        <Row>
                            <h2 className="mb-2">Activos financieros para invertir tus dólares</h2>

                        </Row>
                        <Row className="justify-content-center py-3 py-sm-2 py-md-3 py-md-4 py-lg-5">
                            <Col xs={12} className="d-flex">
                                <div className="text-center d-flex flex-row flex-wrap justify-content-center inversiones-btns"
                                     data-sal="slide-up"
                                     data-sal-delay="0"
                                     data-sal-duration="200"
                                >

                                    <Link className="btna my-4" to="/inversiones/bonos"><Button variant="primary outline" text="Bonos"/></Link>
                                    <Link className="btna my-4" to="/inversiones/cauciones"><Button variant="primary outline" text="Cauciones"/></Link>
                                    <Link className="btna my-4" to="/inversiones/cedears"><Button variant="primary outline" text="CEDEARS"/></Link>
                                    <Link className="btna my-4" to="/inversiones/fondos"><Button variant="primary outline" text="Fondos Comunes de Inversión"/></Link>
                                    <Link className="btna my-4" to="/inversiones/obligaciones-negociables"><Button variant="primary outline" text="Obligaciones Negociables"/></Link>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </section>
                <section className="fullwidth razones">
                    <Container>
                        <Row>
                            <h2 className="mt-2"
                                data-sal="fade"
                                data-sal-delay="0"
                                data-sal-duration="400"
                            >5 razones para invertir tus dólares</h2>
                            <SlickProduct
                                slickSettings={slickSettings}
                                list={slick_items}
                            />
                        </Row>
                    </Container>
                </section>
                
                
                <section className="fullwidthbg pack-de-cedears-banner-2 py-4 py-md-5">
            <Container className="d-flex align-items-center justify-content-center">
                   <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5 w-100 ">
                    <Col xs={12} md={12}>
                        <div className="section-content banner2-Title text-center"
                             data-sal="slide-up"
                             data-sal-delay="0"
                             data-sal-duration="200"
                        >
                            <h3 className="titulo me-3 me-md-3 me-lg-4 me-xl-5 mb-4" ><span className="tit-54"><strong>¿Querés empezar a invertir en Dólares? </strong></span></h3>
                            
                            <span className=" subtitle  me-3 me-md-3 me-lg-4 me-xl-5" ><span className="tit-54">Abrí hoy tu cuenta de inversión en Balanz, sin costo de apertura ni mantenimiento, y accedé al mejor asesoramiento del mercado.</span></span>
                            
                        </div>
                        
                    </Col>
                    <div className="cta mt-5 mb-2 text-center pt-4 p-lg-3"
                             data-sal="slide-up"
                             data-sal-delay="300"
                             data-sal-duration="200"
                        >   
                            <Link to="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1382">
                                <Button variant="secondary" text="Abrir Cuenta"/>
                            </Link>

                            
                        </div> 

                  </Row>
            </Container>
        </section>
                <section className="fullwidth preguntas-frecuentes dolares">
                    <Container>
                        <Row>
                            <Faq faq={faq} customClass={'dolares-faq'} />
                        </Row>
                    </Container>
                </section>
            </SalWrapper>
        </>
    )

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"dolares"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default DolaresPage;
